import { createStyles } from '@material-ui/core/styles';

export const styles = () =>
  createStyles({
    root: {
      lineHeight: 0,
      width: 'inherit',
    },
    image: {
      width: 'inherit',
    },
  });
