import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Link } from '@shared/components/link';
import { ConfigCore } from '@shared/models/config';

import { styles } from './LogoImage.styles';

export interface LogoImageProps extends WithStyles<typeof styles> {
  appLogos: ConfigCore['appLogos'];
  url: string;
  onClick?: () => void;
}

const LogoImageComponent: FC<LogoImageProps> = ({ appLogos, classes, url, onClick }) => {
  return (
    <Link classes={{ root: classes.root }} to={url}>
      <img
        alt={appLogos.alt}
        className={classes.image}
        src={appLogos.large_images[0]}
        srcSet={appLogos.large_images.length > 1 ? appLogos.large_images.join(', ') : undefined}
        title={appLogos.title || undefined}
        onClick={onClick}
      />
    </Link>
  );
};

export const LogoImage = withStyles(styles)(LogoImageComponent);
